/* eslint-disable react-func/max-lines-per-function */
import { createSlice } from '@reduxjs/toolkit';

import { setSubscriptionsReducer } from './subscriptions.reducers';
import { SubscriptionState } from './subscriptions.types';

export const initialState: SubscriptionState = {
  nextRenewalDate: '',
};

export const SubscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptions: setSubscriptionsReducer,
  },
});

export const { setSubscriptions } = SubscriptionsSlice.actions;

export default SubscriptionsSlice.reducer;
