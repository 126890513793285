import { configureStore } from '@reduxjs/toolkit';

import common from './slices/common';
import contactLists from './slices/contact-lists';
import layout from './slices/layout';
import messages from './slices/messages';
import subscriptions from './slices/subscriptions';
import templates from './slices/templates';

export const store = configureStore({
  reducer: {
    layout,
    common,
    messages,
    templates,
    contactLists,
    subscriptions,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
