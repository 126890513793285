/* istanbul ignore file */
import dayjs from 'dayjs';

export const getCampaignsReportUrl = (
  websiteId: string,
  from: Date,
  to: Date
): string =>
  `/website/${websiteId}/hsm/campaigns/report?from=${dayjs(
    from
  ).toISOString()}&to=${dayjs(to).add(1, 'day').toISOString()}`;

export const getAccountLimitsUrl = (websiteId: string): string =>
  `/website/${websiteId}/hsm/account/limit`;

export const getHsmConfigUrl = (websiteId: string): string =>
  `/website/${websiteId}/hsm/hsm-config`;

export const createCampaignUrl = '/hsm/campaign';

export const createCampaignUrlV4 = (websiteId: string): string =>
  `/website/${websiteId}/hsm/v4/campaigns`;

export const cancelCampaignUrl = (
  websiteId: string,
  campaignId: number
): string => `/website/${websiteId}/hsm/v3/campaigns/${campaignId}/cancel`;

export const createContactListUrl = (websiteId: string): string =>
  `/website/${websiteId}/hsm/contact-lists`;

export const createContactListV2Url = (websiteId: string): string =>
  `/website/${websiteId}/hsm/v2/contact-lists`;

export const createTemplateUrl = (websiteId: string): string =>
  `/website/${websiteId}/hsm/templates`;

export const uploadTemplateMediaUrl = (websiteId: string): string =>
  `/website/${websiteId}/hsm/v2/template-media`;

export const createTemplateV2Url = (websiteId: string): string =>
  `/website/${websiteId}/hsm/v2/templates`;

export const getSheetContactsUrl = (sheetid: string): string =>
  `/hsm/contacts/google-sheet/${sheetid}`;

export const getContactsFileExampleUrl = (
  websiteId: string,
  templateId: number | null
): string => {
  let url = `/hsm/contacts/file-example?websiteId=${websiteId}`;

  if (templateId) {
    url = `${url}&templateId=${templateId}`;
  }
  return url;
};

export const postFileContactsUrl = `/hsm/contacts/file`;

export const postWhatsappWebLoginUrl = (websiteId: string): string =>
  `/website/${websiteId}/hsm/web-login`;

export const postWhatsappWebLogoutUrl = (websiteId: string): string =>
  `/website/${websiteId}/hsm/web-logout`;

export const createWebCampaignUrl = (websiteId: string): string =>
  `/website/${websiteId}/hsm/web-campaigns`;

export const getHsmWebsitesUrl = `/hsm/websites`;

export const getUsageMetricsUrl = (companyId: string) =>
  `/company/${companyId}/usage-metrics`;

export const updateTemplateUrl = (websiteId: string, templateId: number) =>
  `/website/${websiteId}/hsm/templates/${templateId}`;

export const getFullCampaignUrl = (websiteId: string, campaignId: string) =>
  `/website/${websiteId}/hsm/campaigns/${campaignId}/full`;

export const getNextRenewalDateUrl = (companyId: string) =>
  `/company/${companyId}/subscription-next-renewal-date`;

export const resendPendingMessagesUrl = (
  websiteId: string,
  campaignId: number
) => `/website/${websiteId}/hsm/campaigns/${campaignId}/resend`;

export const getTemplatesUrl = (websiteId: string) =>
  `/website/${websiteId}/hsm/v2/templates`;
