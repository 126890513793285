import { PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { CommonState } from './common.types';

export const initializeCommonReducer = (
  state: CommonState,
  {
    payload,
  }: PayloadAction<{
    channel: CommonState['currentChannel'];
  }>
) =>
  produce(state, (draft) => {
    const { channel } = payload;

    draft.currentChannel = channel;
  });

export const setCurrentChannelReducer = (
  state: CommonState,
  { payload }: PayloadAction<{ channel: CommonState['currentChannel'] }>
) =>
  produce(state, (draft) => {
    const { channel } = payload;

    draft.currentChannel = channel;
  });

export const fetchAccountLimitReducer = (
  state: CommonState,
  { payload }: PayloadAction<CommonState['tierCurrentLimit']>
) =>
  produce(state, (draft) => {
    draft.tierCurrentLimit = payload;
  });
