import { PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { SubscriptionState } from './subscriptions.types';

export const setSubscriptionsReducer = (
  state: SubscriptionState,
  action: PayloadAction<{ subscription: SubscriptionState }>
) =>
  produce(state, (draft) => {
    const { nextRenewalDate } = action.payload.subscription;

    draft.nextRenewalDate = nextRenewalDate;
  });
