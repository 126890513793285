import { createSlice } from '@reduxjs/toolkit';

import { fetchAccountLimitAction } from './common.async-actions';
import {
  fetchAccountLimitReducer,
  initializeCommonReducer,
  setCurrentChannelReducer,
} from './common.reducers';
import { CommonState } from './common.types';

import { asyncThunkStatus } from '@/constants/common';

export const initialState: CommonState = {
  currentChannel: '',
  tierCurrentLimit: null,
  fetchAccountLimitStatus: asyncThunkStatus.idle,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    initializeCommon: initializeCommonReducer,
    fetchAccountLimit: fetchAccountLimitReducer,
    setCurrentChannel: setCurrentChannelReducer,
  },
  extraReducers(builder) {
    builder.addCase(fetchAccountLimitAction.pending, (state: CommonState) => {
      state.fetchAccountLimitStatus = asyncThunkStatus.pending;
    });
    builder.addCase(fetchAccountLimitAction.fulfilled, (state: CommonState) => {
      state.fetchAccountLimitStatus = asyncThunkStatus.succeeded;
    });
    builder.addCase(fetchAccountLimitAction.rejected, (state: CommonState) => {
      state.fetchAccountLimitStatus = asyncThunkStatus.failed;
    });
  },
});

export const { initializeCommon, fetchAccountLimit, setCurrentChannel } =
  commonSlice.actions;

export default commonSlice.reducer;
